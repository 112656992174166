<script>
import i18n from "@/i18n";
import moment from 'moment-timezone';
import {mapActions, mapState} from "pinia";
import {managerStore} from "@/store/managerStore";
import {baseLoaderStore} from "@/store/baseLoaderStore";
import Button from 'primevue/button';
import ManagerChangeStatusConfirmModal from "@/views/manager/manager-modals/ManagerChangeStatusConfirmModal.vue";


export default {
  name: "ManagerListItem",
  components: {
    Button,
    ManagerChangeStatusConfirmModal
  },
  props: {
    itemData: {
      type: Object
    },
    currentManagerInfo: {
      type: Object
    }
  },
  data() {
    return {
      testAccountProfileIsExist: false,
      testAccountProfileState: false,
      managerTestingEnvironments: null,
      testingProfile: null,
      treeOptions: {
        checkOnSelect: true,
        autoCheckChildren: false,
        checkbox: true,
      },
      changeStatusConfirmModalState: false
    }
  },
  computed: {
    ...mapState(managerStore,[
      'testEnvironment',
    ]),
    treeData() {
      const data = [
        {
          text: i18n.global.t('managerView.manager.permission.taskTypes.contentReviews'),
          children: [],
          autoCheckChildren: false
        },
        {
          text: i18n.global.t('managerView.manager.permission.taskTypes.manageApplications'),
          children: [],
          autoCheckChildren: false
        }
      ];

      this.itemData.rights.forEach((task)=> {
        const item = {
          text: task.taskTitle,
          state: {
            checked: task.status === 'PERMITTED',
            disabled: task.status === 'CLOSED' || this.itemData.status === 'INACTIVE'
          },
          data: {
            taskType: task.taskType
          }
        }

        if (task.taskType.substring(0, 2) === 'CR') {
          data[0].children.push(item)
        } else if (task.taskType.substring(0, 2) === 'AP') {
          data[1].children.push(item)
        }
      });

      return data;
    },
    lastLoginFormattedDate() {
      const date = this.itemData.lastLoginDate.substring(0, this.itemData.lastLoginDate.indexOf("["));
      const timeZone = this.itemData.lastLoginDate.replace( /(^.*\[|\].*$)/g, '' );

      return moment(date).tz(timeZone).format('DD/MMM/YYYY h:mm A');
    },
    hideBasisForInvoice() {
      return this.testingProfile ? this.testingProfile.testing : false;
    },
  },
  methods: {
    ...mapActions(baseLoaderStore, [
      'showBaseLoader',
      'hideBaseLoader'
    ]),
    ...mapActions(managerStore, [
        'getManagersListAction',
        'getTestingProfileAction',
        'updateManagerRights',
        'toggleBasisForInvoiceModal'
    ]),
    onChangeAction(e) {
      if (e.data.taskType) {
        this.updateRights(e);
      }
    },
    updateRights(e) {
      const target = e;
      const taskType = e.data.taskType;
      const status = e.states.checked ? 'PERMITTED' : 'CAN_APPLY';

      target.states.disabled = true;
      this.updateManagerRights(this.itemData.id, taskType, status).then(()=> {
        target.states.disabled = false;
      });
    },
    getManagersData() {
      this.$emit('get-managers-data');
    },
    getTestingProfile() {
      this.getTestingProfileAction(this.testEnvironment.id, this.itemData.userId).then((resp)=> {
        this.testingProfile = resp.data;
        this.testAccountProfileIsExist = !this.testingProfile.testing;
      }).catch(()=> {
        this.testAccountProfileIsExist = false;
      })
    },
    openTestingProfile() {
      const url = this.testEnvironment.hostName + '/manager/contentreviews?page=1&assignees=' + this.testingProfile.externalUserId + '&reviewStatus=RESOLVED';
      window.open(url, '_blank').focus();
    },
    toggleChangeStatusConfirmModalState() {
      this.changeStatusConfirmModalState = !this.changeStatusConfirmModalState;
    },
  },
  updated() {
    this.$refs.tree.setModel(this.treeData)
  },
  mounted() {
    if (this.testEnvironment) {
      this.getTestingProfile();
    }
  },
}
</script>

<template>
<div class="manager-item" :class="{ 'manager-item--disabled': itemData.status === 'INACTIVE' }">
  <div class="manager-item__main">
    <div class="manager-general-info">
      <div class="manage-avatar">
        <img :src="'/viewimage?type=user&sh=' + itemData.avatarHash">
      </div>
      <div>
        <div class="mb-5">
          <span class="user-name">{{ itemData.userFullName }},</span>
          <span class="gender-label">{{ itemData.gender }}</span>
        </div>
        <a :href="'mailto:' + itemData.email" target="_blank">{{ itemData.email }}</a>
      </div>
    </div>
    <div>
      <span>
        {{ lastLoginFormattedDate }}
      </span>
    </div>
    <div class="ml-auto">
        <span v-if="testAccountProfileIsExist"
              class="btn-link"
              @click="openTestingProfile"
        >{{$t('managerView.manager.testAccountProfile')}}</span>
      <span v-if="!hideBasisForInvoice"
            class="btn-link"
            @click="toggleBasisForInvoiceModal({id: itemData.id, name: itemData.userFullName})"
      >{{$t('managerView.manager.basisForInvoice')}}</span>
      <template v-if="itemData.status === 'ACTIVE'">
        <Button :label="$t('managerView.manager.deactivate')"
                @click="toggleChangeStatusConfirmModalState()"
                class="btn"
        />
      </template>
      <template v-else>
        <Button :label="$t('managerView.manager.activate')"
                @click="toggleChangeStatusConfirmModalState()"
                class="btn"
        />
      </template>
    </div>
  </div>
  <div class="permission-tree-section">
    <h3 class="permission-tree-section__title">
      {{$t('managerView.manager.permissionSettings')}}
    </h3>
    <tree :data="treeData"
          ref="tree"
          :options="treeOptions"
          @node:checked ="onChangeAction"
          @node:unchecked ="onChangeAction"
    />
  </div>
  <manager-change-status-confirm-modal :modal-state-param="changeStatusConfirmModalState"
                                       :manager-data="itemData"
                                       @on-change-status="getManagersData"
                                       @on-close="toggleChangeStatusConfirmModalState()"
  />
</div>
</template>

<style lang="scss">
.manager-item {
  display: flex;
  flex-direction: column;
  padding: 16px 18px;
  border: 1px solid #cccccc;
  border-bottom: none;
  box-sizing: border-box;

  &__main {
    display: flex;
    margin-bottom: 20px;
  }

  &--disabled {
    background: #e3e3e3;

    .tree-content {
      &:hover {
        background: transparent !important;
      }
      .tree-anchor {
        background: transparent !important;
      }
    }
  }

  &:last-child {
    border: 1px solid #cccccc;
  }

  .manager-general-info {
    display: flex;
    width: 380px;

    span {
      color: #4f4f4f;
    }

    .manage-avatar {
      width: 64px;
      height: 64px;
      overflow: hidden;
      margin-right: 16px;
      border-radius: 64px;
      background: #cccccc;

      img {
        width: 64px;
      }
    }

    .user-name {
      font-weight: 600;
    }

    .gender-label {
      display: inline-block;
      text-transform: lowercase;
      &:first-letter {
        text-transform: uppercase;
      }
    }
  }

  .btn-link {
    font-weight: 600;
    color: #7835a6;
    margin-right: 25px;
    cursor: pointer;
  }
}

.tree-node.has-child {
  .tree-content:first-child {
    .tree-checkbox {
      display: none;
    }
    .tree-anchor {
      padding-left: 0;
    }
  }
  .tree-children {
    .tree-node {
      .tree-checkbox {
        display: initial;
      }
      .tree-anchor {
        padding-left: 6px;
      }
    }
  }
}

.permission-tree-section {
  &__title {
    margin-bottom: 5px;
    font-weight: 600;
  }
}
</style>