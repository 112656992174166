import {defineStore} from "pinia";
import axios from "axios";
import {CompensationRate} from "@/store/model/CompensationRate";

export const managerStore = defineStore("managerStore", {
        state: () => ({
                managersList: [],
                managersListPage: 0,
                managersListTotalPages: 1,

                managerBasisForInvoiceModalState: false,
                managerBasisForInvoiceLoadParams: null,

                compensationInfo: null,

                jobItemsList: [],
                ratesList: [],
                ratesLogModalState: false,
                ratesLogLoadParams: null,
                testEnvironment: null
        }),
        getters: {

        },
        actions: {
                //Managers list
                getManagersListAction(page, perPage, filterParams) {
                        let url = `/api/manager/profile/list?page=${page}&size=${perPage}`;

                        if (filterParams) {
                                url += '&' + new URLSearchParams(filterParams).toString();
                        }

                        return axios.get(url).then((response) => {
                                this.managersList = response.data.content;
                                this.managersListPage = response.data.number;
                                this.managersListTotalPages = response.data.totalPages === 0 ? 1 : response.data.totalPages;
                        });
                },
                updateManagerRights(id, taskType, status) {
                        return axios.put(`/api/manager/profile/${id}/rights/${taskType}/${status}`);
                },
                toggleManagerStatusAction(id, status) {
                        return axios.put(`/api/manager/profile/${id}/status/${status}`);
                },
                getTestingProfileAction(testEnvironmentId, userId) {
                        return axios.get(`/api/manager/testing/profiles?verifyStatus=false&environmentId=${testEnvironmentId}&userId=${userId}`);
                },

                //Job openings actions
                getJobItems() {
                        return axios.get('/api/manager/job/counts').then((resp) => {
                                this.jobItemsList  = resp.data;
                        });
                },
                openJobAction(taskType, amount)  {
                        return axios.put(`/api/manager/job/${taskType}/open?amount=${amount}`);
                },
                closeJobAction(taskType) {
                        return axios.put(`/api/manager/job/${taskType}/close`);
                },

                //Basis for invoice modal
                toggleBasisForInvoiceModal(params) {
                        this.managerBasisForInvoiceLoadParams = params ? params : null;
                        this.managerBasisForInvoiceModalState = !this.managerBasisForInvoiceModalState;
                },
                getCompensationBasisInfoAction(date, managerId) {
                        return axios.get(`/api/manager/compensation/basis/${date}?managerId=${managerId}`);
                },

                //Manager rates
                getRatesInfoAction() {
                        return axios.get('/api/manager/compensation/rates').then((resp) => {
                                this.ratesList = resp.data.map(i => new CompensationRate(i));
                        });
                },
                toggleRatesLogModalState(params) {
                        this.ratesLogLoadParams = params ? params : null;
                        this.ratesLogModalState = !this.ratesLogModalState;
                },
                getRateVersionsAction(params) {
                        return axios.get(`/api/manager/compensation/${params.type}/rates`);
                },
                getTaskAverageAction(month) {
                        return axios.get(`/api/manager/task/assign/average?month=${month}`);
                },
                setCompensationInfo(data) {
                        this.compensationInfo = data;
                },
                createNewRatePeriod(type) {
                        return axios.post(`/api/manager/compensation/rates?type=${type}`);
                },


                //Test environment
                getTestEnvAction() {
                        return axios.get('/api/manager/testing/environments').then((response)=> {
                                this.testEnvironment = response.data[0];
                        });
                },
        }
});