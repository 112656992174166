<script>
import {mapActions, mapState} from "pinia";
import {managerStore} from "@/store/managerStore";
import Dialog from "primevue/dialog";
import BasisForInvoiceSection from "@/views/manager/ManagerBasisForInvoice/BasisForInvoiceSection.vue";

export default {
  name: "ManagerBasisForInvoiceModal",
  components: {
    Dialog,
    BasisForInvoiceSection
  },
  computed: {
    ...mapState(managerStore, [
      'managerBasisForInvoiceModalState',
      'managerBasisForInvoiceLoadParams'
    ]),
    modalState: {
      get() {
        return this.managerBasisForInvoiceModalState;
      },
      set() {
        this.closeModal();
      },
    },
    modalTitle() {
      return `${this.managerBasisForInvoiceLoadParams.name} - Basis for invoice`
    }
  },
  methods: {
    ...mapActions(managerStore, [
      'toggleBasisForInvoiceModal'
    ]),
    closeModal() {
      this.toggleBasisForInvoiceModal();
    }
  }
}
</script>

<template>
  <Dialog v-model:visible="modalState"
          class="basis-for-invoice-modal"
          modal
          :header="modalTitle"
  >
    <div>
      <basis-for-invoice-section/>
    </div>
    <template #footer>
      <button type="button" class="p-dialog-close-btn" @click="closeModal()">
        {{$t('common.buttons.close')}}
      </button>
    </template>
  </Dialog>
</template>

<style lang="scss">
.basis-for-invoice-modal {
  width: 1075px;
  .p-dialog-content {
    padding: 0;
  }
}
</style>